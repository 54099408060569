
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import UrlHelper from "@/core/helpers/UrlHelper";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "reset-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const submitButton = ref<HTMLButtonElement | null>(null);
  const {t} = useI18n();
    //Create form validation object
    const resetPassword = Yup.object().shape({
      password: Yup.string().min(8).max(25).required().label("Password"),
      confirm_password: Yup.string()
          .min(8)
          .max(25)
          .required()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .label("Password Confirmation"),
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // eslint-disable-next-line
      values.domain = UrlHelper.getRequestHostname();
      values.token = UrlHelper.getUrlParam('token');
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // dummy delay
      setTimeout(() => {
        // Send login request
        store
            .dispatch(Actions.RESET_PASSWORD, values)
            .then(() => {
              Swal.fire({
                text: t("password_changed_successfuly"),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function() {
                router.push({ name: "sign-in" });
              });
            })
            .catch(() => {
              const [error] = Object.keys(store.getters.getErrors);
              // Alert then login failed
              Swal.fire({
                text: store.getters.getErrors.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });

        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 2000);
    };

    return {
      onSubmitForgotPassword,
      resetPassword,
      submitButton,
    };
  },
});
